import React, {useState} from 'react';
import checkViewport from '../../Hooks/checkViewport';
import { Toolbar, AppBar, Grid, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './NavBar.module.css'
import mainLogo from '../../assets/CroppedLogo.png';

const NavBar = (props) =>{    
  const [showNav, setShowNav] = useState(false);
  const {activeComponent, setActiveComponent} = props;
  const width = checkViewport();
  const breakpoint = 750;

  return (
    <AppBar className={styles.mainAppBar} position="static">
        {
          width < breakpoint ? 
          <>
            <Toolbar>
              <div className={styles.navItems_mobile}>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={() =>{setShowNav(!showNav)}}>
                  <MenuIcon className={styles.menuIcon}/>
                </IconButton>
                <img alt="Mrs. Grab Bar Company logo" src={mainLogo} className={styles.navItems_logo} onClick={() => {setActiveComponent("Home")}}/> 
              </div>
            </Toolbar>
          {showNav ? 
          <>
            <div className={activeComponent === "Home" ? styles.navBarItem_Active_mobile : styles.navBarItem_mobile} onClick={() => {setActiveComponent("Home"); setShowNav(false);}}>
              <h3 className={styles.navBarItem_Text}>Home</h3>
            </div>
            <div className={activeComponent === "Gallery" ? styles.navBarItem_Active_mobile : styles.navBarItem_mobile} onClick={() => {setActiveComponent("Gallery"); setShowNav(false);}}>
              <h3 className={styles.navBarItem_Text}>Gallery</h3>
            </div>
            <div className={activeComponent === "About" ? styles.navBarItem_Active_mobile : styles.navBarItem_mobile} onClick={() => {setActiveComponent("About"); setShowNav(false);}}>
              <h3 className={styles.navBarItem_Text}>About</h3>
            </div>
            <div className={activeComponent === "Contact" ? styles.navBarItem_Active_mobile : styles.navBarItem_mobile} onClick={() => {setActiveComponent("Contact"); setShowNav(false);}}>
              <h3 className={styles.navBarItem_Text}>Contact Us</h3>
            </div>
          </>
                  :
          null}          
          </>
                        :
          <Toolbar className={styles.mainAppToolBar}>
            <Grid item xs={6}>
              <img alt="Mrs. Grab Bar Company logo" src={mainLogo} className={styles.navItems_logo} onClick={() => {setActiveComponent("Home")}}/>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.navItems}>
                <div className={activeComponent === "Home" ? styles.navBarItem_Active : styles.navBarItem} onClick={() => {setActiveComponent("Home")}}>
                  <h3 className={styles.navBarItem_Text}>Home</h3>
                </div>
                <div className={activeComponent === "Gallery" ? styles.navBarItem_Active : styles.navBarItem} onClick={() => {setActiveComponent("Gallery")}}>
                  <h3 className={styles.navBarItem_Text}>Gallery</h3>
                </div>
                <div className={activeComponent === "About" ? styles.navBarItem_Active : styles.navBarItem} onClick={() => {setActiveComponent("About")}}>
                  <h3 className={styles.navBarItem_Text}>About</h3>
                </div>
                <div className={activeComponent === "Contact" ? styles.navBarItem_Active : styles.navBarItem} onClick={() => {setActiveComponent("Contact")}}>
                  <h3 className={styles.navBarItem_Text}>Contact Us</h3>
                </div>
              </div>
            </Grid>
          </Toolbar>   
        }
    </AppBar>
  );
}

export default NavBar;
