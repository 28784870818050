import {useState, useEffect} from 'react';
let defaultWidth;

if(typeof window !== `undefined`){
    defaultWidth = window.innerWidth;
}

const CheckViewport = () =>{
    const [width, setWidth] = useState(defaultWidth);
    useEffect(() =>{
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    return width;
}

export default CheckViewport;



