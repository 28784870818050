import React from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './About.module.css';
import staffPhoto from '../../../assets/patrickWorking.jpg';
import customerPhoto from '../../../assets/customerWithBar.JPG';
import showerBars from '../../../assets/showerBars.JPG';
import showerBars2 from '../../../assets/showerBars2.jpeg';

const About = () =>{    

  return (
    <Grid container style={{width: '100vw'}}>
      <Grid item xs={12}>
        <h1 className={styles.pageHeader}><span>About Us</span></h1>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.about_contentContainer}>
          <div className={styles.about_photoContainer}>
            <img alt="Customer holding a bar" className={styles.about_photo} src={customerPhoto} />
          </div>
          <div>
            <div className={styles.about_textDiv}>
              <h2 className={styles.pageSubHeader}>What We Do</h2>
              <p className={styles.about_text}>We are a northeast Ohio company devoted to making living in your home viable for as long as you like. Our products include grab bars, railings, bath faucets, hand held shower heads, lighting, smoke and CO detectors, carpet removal, vinyl plank flooring, tall toilets, and simple handyperson jobs.</p>
            </div>
          </div>
        </div>
        <div className={styles.about_contentContainer}>
          <div className={styles.about_photoContainer}>
            <img alt="Grab bars" className={styles.about_photo} src={showerBars} />
          </div>
          <div>
            <div className={styles.about_textDiv}>
              <h2 className={styles.pageSubHeader}>Worried about Mom, Dad, Grandparents?</h2>
              <p className={styles.about_text}>We are your problem solvers.  We like to to a home safety inspection before an accident occurs. We do a detailed analysis and make recommendations based on the individual needs of each person in the home.  We can also install the products required by PT, OT, Social Worker, Home healthcare worker, etc.</p>
            </div>
          </div>
        </div>
        <div className={styles.about_contentContainer}>
          <div className={styles.about_photoContainer}>
            <img alt="Employee installing grab bars" className={styles.about_photo} src={staffPhoto} />
          </div>
          <div>
            <div className={styles.about_textDiv}>
              <h2 className={styles.pageSubHeader}>Who We Are</h2>
              <p className={styles.about_text}>Mrs Grab Bar LLC offers Professional inspection, products, and installation to prevent falls and accidents in your home. Our team includes Senior Home Safety Advisors and Certified Product Installers. Each team member is background checked and drug tested for your security.</p>
            </div>
          </div>
        </div>
        <div className={styles.about_contentContainer}>
          <div className={styles.about_photoContainer}>
            <img alt="Grab bars" className={styles.about_photo} src={showerBars2} />
          </div>
          <div>
            <div className={styles.about_textDiv}>
              <h2 className={styles.pageSubHeader}>Mrs. Grab Bar LLC</h2>
              <p className={styles.about_text}>We know it's not easy asking for help or accepting help.  We've been in the accessibility business since 2001.  Our experience with quality products and people enable us to care for your needs, current and future.</p>
            </div>
          </div> 
        </div>
      </Grid>
    </Grid>
  );
}

export default About;