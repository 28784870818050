import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './Email.module.css';
import handleEmail from '../../../../Hooks/handleEmail';

const Email = () =>{    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [showError, setShowError] = useState(null);
    const [emailMessage, setEmailMessage] = useState(null);

    const submitEmail = async() =>{
        const emailObject = {
            name: name,
            email: email,
            phone: phone,
            message: message
        }
        
        const emailResponse = await handleEmail(emailObject);
        setShowError(emailResponse.mailFailed);
        setEmailMessage(emailResponse.mailMessage);
    }

    return (
        <Grid container className={styles.helpContainer_emailContainer}>
            {
                emailMessage ?
                    <Grid item xs={12}>
                        <p className={!showError ? styles.about_text : styles.about_text_Error}>{emailMessage}</p>
                    </Grid>
                              :
                null
            }
            <Grid item xs={12}>
                <div>
                    <input 
                        className={!showError ? styles.helpContainerSubjectInput : styles.helpContainerSubjectInput_Error}
                        type='text'                                    
                        name='name'
                        value={name}
                        onChange={(e) =>{setName(e.target.value)}}
                        placeholder='* Name'
                        required
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <input 
                        className={!showError ? styles.helpContainerSubjectInput : styles.helpContainerSubjectInput_Error}
                        type='text'                                    
                        name='email'
                        value={email}
                        onChange={(e) =>{setEmail(e.target.value)}}
                        placeholder='* Email address'
                        required
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <input 
                        className={styles.helpContainerSubjectInput}
                        type='text'                                    
                        name='phone'
                        value={phone}
                        onChange={(e) =>{setPhone(e.target.value)}}
                        placeholder='Phone number'
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <textarea
                        className={!showError ? styles.helpContainerMessageInput : styles.helpContainerMessageInput_Error}
                        name='message'
                        value={message}
                        onChange={(e) =>{setMessage(e.target.value)}}
                        placeholder='* Message'
                        required
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                {showError === false?
                    <div className={styles.sendButton_Dull}>
                        <p className={styles.sendButton_text_Dull}>SEND</p>
                    </div>
                                    :
                    <div className={styles.sendButton} onClick={() =>{submitEmail()}}>
                        <p className={styles.sendButton_text}>SEND</p>
                    </div>      
                }
            </Grid>
        </Grid>
    );
}

export default Email;