import app from '../firebase/initFirebase';
const db = app.firestore();


const GetPhotos = async () =>{
    return db.collection('galleryPhotos').get().then(function(querySnapshot){
        const retrievedPhotos = [];
        querySnapshot.forEach(function(doc){
            if(doc.data().photoUrl){
                retrievedPhotos.push({photoUrl: doc.data().photoUrl, photoId: doc.id})
            }
        });
        return retrievedPhotos;
    }).catch(err =>{
        console.log(err, "THE ERROR")
        return[]
    });
}

export default GetPhotos;