import emailjs from 'emailjs-com';

const HandleEmail = async (emailObject) =>{
    const inputsValid = validateInputs(emailObject);
    const emailValid = validEmail(emailObject.email);
    if(inputsValid && emailValid){
        const templateParams = {
            from_name: emailObject.phone.trim() === '' ? emailObject.name : `${emailObject.name} (${emailObject.phone})`,
            from_email: emailObject.email,
            to_name: "Mrs. Grab Bar",
            subject: `New Mrs. Grab Bar inquiry from: ${emailObject.email}`,
            message_html: emailObject.message
        };

        const emailResponse = await emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
            return {mailFailed: false, mailMessage: "Thank you! We got your email and will be in touch shortly!"}
        }, (error) => {
            return {mailFailed: true, mailMessage: "Something went wrong, please try again later."};
        });

        return emailResponse;
    }else{
        return {mailFailed: true, mailMessage: "Please check that all required inputs are filled out and that your email is a valid."};
    }
}

const validateInputs = (emailObject) =>{
    if(emailObject.name.trim() === ''){
        return false;
    }else if(emailObject.message.trim() === ''){
        return false;
    }else if(emailObject.email.trim() === ''){
        return false;
    }else{
        return true;
    }
}

const validEmail = (email) =>{
    const validEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(validEmail.test(email)){
        return true;
    }else{
        return false;
    }
}

export default HandleEmail;