import React, {useState, useEffect} from 'react';
import NavBar from '../NavBar/NavBar';
import styles from './RootContainer.module.css';
import Grid from '@material-ui/core/Grid';
import Home from './Home/Home';
import Gallery from './Gallery/Gallery';
import Contact from './Contact/Contact';
import About from './About/About';
import getPhotos from '../../Hooks/getPhotos';

const RootContainer = () =>{    
  const [activeComponent, setActiveComponent] = useState("Home");
  const [instagramPhotos, setInstagramPhotos] = useState([]);

  useEffect(() =>{
    async function fetchPhotos(){
      const retrievedPhotos = await getPhotos();
      setInstagramPhotos(retrievedPhotos);
    }
    fetchPhotos()
  }, [])
  return (
    <Grid container>
      <NavBar activeComponent={activeComponent} setActiveComponent={setActiveComponent}/>
        {
          activeComponent === "Home" ?
            <Home />
          : activeComponent === "Gallery" ?
            <Gallery photos={instagramPhotos}/>
          : activeComponent === "Contact" ? 
            <Contact />
          : activeComponent === "About" ?
            <About /> : null
        }
      <div className={styles.websiteFooter}>
        <p>© Mrs Grab Bar LLC.</p>
      </div>
    </Grid>
  );
}

export default RootContainer;
