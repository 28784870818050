const customerTestimonials = [
    {
        testimonial: "I thank you every day when I use my grab bars to get in and out of the shower.",
        customer: "Linda, Berea, OH",
        id: 1
    },
    {
        testimonial: "Being my husband's caregiver is not easy. The grab bars you installed in his bathroom help both of us. I appreciate the time Patrick took with us to install them in the best place. I am using the railing to the garage, too. Thank you.",
        customer: "Karen, Independence, OH",
        id: 2
    },
    {
        testimonial: "My Mom is navigating her home much easier without the carpet. Thank you for recommending & removing the carpet in the living and dining rooms over hardwood. Thanks, also, for the railing in the hall and the grab bars in the bathroom.",
        customer: "Carla, Beachwood, OH",
        id: 3
    },
    {
        testimonial: "Thank you for the beautiful work you did on our home. Your crew's attention to detail is amazing. We are so happy",
        customer: "Anne, Mayfield Heights, OH",
        id: 4
    },
    {
        testimonial: "I just wanted to thank you. Every part of working with your company is impressive-the organization, product knowledge, and turn around time are outstanding",
        customer: "Dean, Medina, OH",
        id: 5
    },
    {
        testimonial: "Love the grab bars. I'm so happy you do them",
        customer: "George, Medina, OH",
        id: 6
    },
    {
        testimonial: "Thank you for working with my Mom. She is comfortable using the hall and bathroom grab bars. She intends to stay in her home as long as she feels safe. She is safer now",
        customer: "Lisa, North Royalton, OH",
        id: 7
    },
    {
        testimonial: "I'm so happy with the work you did at my house! I've already referred you to two neighbors!",
        customer: "JoEllen, Medina, OH",
        id: 8
    },
    {
        testimonial: "Just want to thank you for installing the outside railing on my son in law's front porch steps. He can use the front door now. He also wanted to thank your installer for treating him respectfully. As you know, he's a young disabled veteran.",
        customer: "Michele, Mentor, OH",
        id: 9
    }
];

const GetTestimonials = () =>{
    // useEffect(() =>{
    //     setTestimonials(testimonials);
    // }, [])
    return customerTestimonials
}

export default GetTestimonials;