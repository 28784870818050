import React from 'react';
import Grid from '@material-ui/core/Grid';
import Email from './Email/Email';
import styles from './Contact.module.css';

const Contact = () =>{    

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1 className={styles.pageHeader}><span>Contact Us</span></h1>
        <h2 className={styles.pageSubHeader}>Phone: 216-990-5505</h2>
      </Grid>
      <Grid item xs={12}>
        <h2 className={styles.pageSubHeader}>Email Us Directly</h2>
        <Email />
      </Grid>
    </Grid>
  );
}

export default Contact;