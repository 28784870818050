import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './Home.module.css';
import companyPhoto from '../../../assets/CroppedFullLogo.png';
import Carousel from 'react-material-ui-carousel';
import getTestimonials from '../../../Hooks/getTestimonials';

const Home = () =>{
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() =>{
    async function fetchTestimonials(){
      const retrievedTestimonials = await getTestimonials();
      setTestimonials(retrievedTestimonials);
    }

    fetchTestimonials();
  }, []);

  const mappedTestimonials = testimonials.map((testimonial, i) =>{
    return(
      <div className={styles.testimonial_div} key={testimonial.id}>
        <p className={styles.testimonial_text}>{testimonial.testimonial}</p>
        <p className={styles.testimonial_customer}>-{testimonial.customer}</p>
      </div>
    )
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1 className={styles.pageHeader}><span>Mrs Grab Bar LLC</span></h1>
      </Grid>
      <Grid item xs={12}>
        <h2 className={styles.pageSubHeader}>Live Safely In Your Northeast Ohio Home</h2>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.mainPhotoDiv}>
          <img alt="Company logo" className={styles.mainPhoto} src={companyPhoto}/>
        </div>
      </Grid>
      <Grid item xs={12}>
        <h2 className={styles.pageSubHeader}>Is Your Home Healthy And Safe? </h2>
        <p className={styles.about_text}>We offer grab bars, railings, and more to make your home safe</p>
      </Grid>
      <Grid item xs={12}>
        <Carousel className={styles.homeCarousel} interval={8000} navButtonsAlwaysVisible={true}>
          {mappedTestimonials}
        </Carousel>
      </Grid>
    </Grid>
  );
}

export default Home;