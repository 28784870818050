import React, {useState, useEffect} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import styles from './Gallery.module.css';
import ImageGallery from 'react-image-gallery';
import { CircularProgress, Grid, Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Gallery = (props) =>{    
  const {photos} = props;
  const [showSpinner, setShowSpinner] = useState(photos.length !== 0);
  const [images, setImages] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState(null);

  useEffect(() => {
    const galleryPhotos = photos.map((photo, i) =>{
      return (
        {
          original: photo.photoUrl,
          thumbnail: photo.photoUrl,
          originalClass: styles.galleryPhoto
        }
      );
    });
    setImages(galleryPhotos);
    setShowSpinner(false);
  }, [photos]);

  const handleClick = (photo) =>{
    setOpenImage(true);
    setImageToDisplay(photo);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1 className={styles.pageHeader}><span>Gallery</span></h1>
      </Grid>
      <div className={styles.galleryCarousel_div}>
        {
          showSpinner ? 
          <CircularProgress color="primary"/> 
                      :
          <ImageGallery items={images} showPlayButton={false} infinite={true} additionalClass={styles.image_gallery} showFullscreenButton={false} onClick={(e) =>{handleClick(e.target.src)}}/>         
        }
      </div>
      <Modal
        open={openImage}
        onClose={() => {setOpenImage(false)}}
      > 
      <div>
        <IconButton className={styles.iconDiv}>
          <CloseIcon className={styles.closeIcon} onClick={() =>{setOpenImage(false)}}/>
        </IconButton>
        <div className={styles.modal_imageDiv}>
          <img alt="Example of work including grab bars and flooring" src={imageToDisplay} className={styles.modal_image}/>
        </div>
      </div>
      </Modal>
    </Grid>
  );
}

export default Gallery;
