import React from 'react';
import RootContainer from '../src/Components/RootContainer/RootContainer';
import "react-image-gallery/styles/css/image-gallery.css";
import './App.css';
require('dotenv').config();

function App() {
  return (
    <RootContainer />
  );
}

export default App;
